#all-sellers .seller_section_header {
  margin-bottom: 25px;
  background-color: var(--second-cards-color);
  padding: 15px;
  border-radius: 5px;
}
#all-sellers .seller_section_header p {
  font-size: 18px;
  line-height: 32px;
  color: var(--primary-color);
  letter-spacing: 2px;
  font-weight: 700 !important;
  margin: 0;
}
#all-sellers .seller_section_header a {
  font-size: 14px;
  font-weight: 400 !important;
  letter-spacing: 1px;
  color: var(--secondary-color);
  text-decoration: none;
  margin-left: 10px;
}

#all-sellers .seller_section_header .seller_button {
  border-radius: 8px;
  font-size: 14px;
  /* padding: 8px 16px; */
  padding: 7px;
  text-decoration: none;
  background-color: var(--secondary-color);
  text-align: center;
  color: var(--text-field-color) !important;
}

#all-sellers .seller_section_header .prev-arrow-seller {
  position: relative;
  display: inline-block;
  text-align: center;
  color: #888 !important;
  top: 0;
  right: 5%;
  padding: 8px;
  background: var(--second-cards-color);
  box-shadow: 0 0 1px 1px rgb(20 23 28 / 10%), 0 3px 1px 0 rgb(20 23 28 / 10%);
  font-size: 15px;
  z-index: 10;
  opacity: 0.7 !important;
  visibility: visible;
  border-radius: 100%;
  transition: all 300ms linear;
}
#all-sellers .seller_section_header .next-arrow-seller {
  position: relative;
  display: inline-block;
  text-align: center;
  color: #888 !important;
  top: 0;
  right: 0;
  padding: 8px;
  background: var(--second-cards-color);
  box-shadow: 0 0 1px 1px rgb(20 23 28 / 10%), 0 3px 1px 0 rgb(20 23 28 / 10%);
  font-size: 15px;
  z-index: 10;
  opacity: 0.7 !important;
  visibility: visible;
  border-radius: 100%;
  transition: all 300ms linear;
}
[dir="rtl"] #all-sellers .seller_section_header .see_sellers_arrow {
  transform: rotate(180deg);
}
[dir="rtl"] #all-sellers .seller_section_header .next-arrow-seller {
  order: 0;
}
[dir="rtl"] #all-sellers .seller_section_header .prev-arrow-seller {
  order: 1;
}
.allSellersContainer .cover {
  height: 200px;
}

.allSellersContainer .cover img {
  height: 100%;
  width: 100%;
  filter: brightness(50%);
  z-index: -1;
  background-size: cover;
  background-position: center center;
}

.allSellersContainer .cover .page-heading {
  position: relative;
  bottom: 100px;
  text-align: center;
}

.allSellersContainer .cover .page-heading h5 {
  color: var(--text-field-color);
  font-size: 3rem;
}

.allSellersContainer .cover .page-heading p {
  color: var(--text-field-color);
  font-size: 2rem;
}

.allSellersContainer .cover .page-heading p span {
  color: var(--secondary-color);
}

.allSellersContainer .content .card {
  border-radius: 8px;

  cursor: pointer;
}
.allSellersContainer.home .content .card {
  border-radius: 8px;

  margin: 5px;
}

.allSellersContainer .content .card img {
  width: 100%;
  /* height: 26rem; */
  border-radius: 8px 8px 0px 0px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.allSellersContainer .content .card .card-body {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.allSellersContainer .content .card:hover {
  outline: var(--secondary-color);
  transform: none !important;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color) !important;
}

.allSellersContainer .content .card .card-body:hover p {
  color: var(--secondary-color) !important;
}

.allSellersContainer .content .card:hover .card-body p {
  color: var(--secondary-color) !important;
}

.allSellersContainer .content .card .card-body p {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  display: block;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
