[dir="rtl"] .Manu-links ul li:last-of-type {
  margin-right: 30px;
}
.slick-slider {
  flex: 0 0 auto;
  width: 100% !important;
  padding: 0 !important;
  padding-left: -2px;
}
.product-details-view .top-wrapper .slick-slide {
  width: auto !important;
}
.product-details-view .top-wrapper .slick-track {
  width: max-content !important;
}
.css-b62m3t-container {
  width: 100%;
}

.slick-prev {
  left: -36px !important;
  z-index: 10 !important;
}

.slick-prev::before {
  content: "" !important;
}

.prev-arrow {
  position: absolute;
  background: var(--second-cards-color);
  border-radius: 8px 0px 0px 8px;
  width: 40px;
  height: 80px;
  /* right: -20px; */
  left: 25px;
  top: -250%;
  padding: 8px;
  box-shadow: 4px 0px 100px 5px rgba(0, 0, 0, 0.2);
  border: 5px solid #f7f7f7;
}

.prev-arrow:hover {
  background-color: var(--secondary-color);
}

.next-arrow {
  position: absolute;
  background: var(--second-cards-color);
  border-radius: 0pc 8px 8px 0px;
  width: 40px;
  height: 80px;
  right: 0px;
  top: -250%;
  padding: 8px;
  box-shadow: 4px 0px 100px 5px rgba(0, 0, 0, 0.05);
  border: 5px solid #f7f7f7;
}

@media (max-width: 467px) {
  .next-arrow {
    /* right: -10px; */
  }

  .prev-arrow {
    /* right: -25px; */
  }
}
@media (min-width: 1300px) {
  .prev-arrow {
    /* right: -42px !important; */
  }
  .next-arrow {
    /* right: -10px !important; */
  }
}

.next-arrow:hover {
  background-color: var(--secondary-color);
}

.slick-next {
  margin-right: 12px !important;
}

.slick-next::before {
  content: "" !important;
}

.m-50 {
  margin: 50px 0px;
}

.product-heading-container {
  background: var(--second-cards-color);
  border-radius: 8px;
  padding: 9px;
  margin-bottom: 20px;
}

.product_section {
  background: var(--second-cards-color);
  padding: 15px !important;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.product_title_content {
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  position: relative;
}

.product_title_content::before {
  content: "";
  bottom: -3px;
  left: 0;
  position: absolute;
  background: var(--secondary-color);
  width: 100px;
  height: 5px;
  border-radius: 10%;
}

.product_title_content span {
  font-size: 14px;

  font-weight: 400 !important;
  letter-spacing: 1px;
  color: var(--secondary-color);
}

.variant_value {
  font-weight: 600 !important;
  font-size: 14px !important;
  margin-top: 10px !important;
  margin-bottom: 0 !important;
  color: #bbb !important;
}

.variant_value_a {
  color: var(--text-field-color) !important;
}

.product_title_content p {
  font-size: 18px;
  line-height: 32px;

  color: var(--primary-color);
  letter-spacing: 2px;
  font-weight: 700 !important;
  margin: 0;
}

.product_title_content a {
  background: var(--secondary-color-light);
  border: 1px solid var(--secondary-color);
  border-radius: 8px;

  font-size: 16px;
  padding: 8px 16px;
  text-decoration: none;
  color: var(--secondary-color);
  text-align: center;
}

.product_title_content a:hover {
  background: var(--secondary-color);
  color: var(--text-field-color);
}

.product_section_content {
  margin-top: 10px !important;
}
.product_section_content .product-card-body .ratings {
  font-size: 14px;
}

.product-card {
  color: var(--primary-color);
  background: var(--second-cards-color);
  border-radius: 8px;
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  overflow: visible;
  position: relative;
}

.product-card:hover,
a:focus .product-card {
  box-shadow: 0 0 15px rgb(0 0 0 / 5%);
  /* transform: scale(1.003); */
  border: 1px solid var(--secondary-color) !important;

  cursor: pointer;
}

.product-card .image-container {
  position: relative;
}

#aiEye {
  font-size: 22px;
  background: var(--second-cards-color);
  line-height: 34px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 0px !important;
  border-radius: 100% !important;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 8%);
}

#aiEye:hover {
  background: var(--secondary-color);
}

.product-card .image-container a:hover svg {
  filter: invert(1);
}
.out_of_stockOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* set opacity to 60% */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px 5px 0 0;
  z-index: 1; /* make sure it's on top of the image */
}

.out_of_stockText {
  color: red;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 4px 8px;
  background: var(--second-cards-color);
  border-radius: 5px;
}
.product-card .image-container img {
  height: auto;
  width: 100%;
  /* padding: 15px; */
  aspect-ratio: 3/3;
  object-fit: fill;
  border-radius: 8px 8px 0px 0px;
}

.product-card .product-card-body {
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 115px;
}
.product-card .product-card-body .price {
  display: flex;
}

.product-card .product-card-body h3 {
  font-weight: 600;
  font-size: 14px;
  color: var(--primary-color);
  width: 100%;
  float: left;
  letter-spacing: 0.2px;
  display: block;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-card .product-card-body h3:hover {
  color: var(--secondary-color);
}

.dropDownLi {
  border-bottom: 1px solid #888;
}

.dropdown-menu {
  padding: 0 !important;
  z-index: 1 !important;
}

.dropdown-menu li:hover {
  background: var(--secondary-color) !important;
}

.dropdown-menu li:hover .react-share__ShareButton span {
  color: var(--text-field-color) !important;
}

.dropdown-menu li button {
  padding: 10px !important;
  padding: 10px;
}

.react-share__ShareButton {
  background-color: transparent;
  border: none;
  padding: 0px;
  font: inherit;
  display: flex;
  color: inherit;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.react-share__ShareButton span {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.2px;
  margin-left: 5px;
}

.product-card .product-card-body .option_class,
.variant_selection {
  background-color: var(--container-bg) !important;
  color: black !important;
  border-radius: 5px;
  font-size: 14px !important;
  width: 100%;
  padding: 4px 10px !important;
  opacity: 0.7;
}

.product-card .product-card-body .variant-section {
  width: 100%;
}

.product-card .product-card-body .variant_selection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 0;
  align-items: center;
}

.product-card .product-card-body .dropdown-menu {
  opacity: 1;
  font-size: 14px;
}

.product-card .product-card-body .dropdown-menu a:active {
  background-color: var(--secondary-color);
}

.product-card .product-card-body .original-price {
  text-decoration: line-through !important;
}

.variant_selection .options_class {
  padding: 8px 16px;
  line-height: 9px;
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 400;
}

.variant_selection .options_class .original-price {
  text-decoration: line-through !important;
}

.product-card .product-card-body .price span {
  color: var(--secondary-color) !important;
  width: 100%;
  font-weight: 700;

  font-size: 18px;
  width: 100%;
  float: left;
  letter-spacing: 0.2px;
}
.product-card .product-card-body .price span p {
  margin: 0 !important;
  padding: 0 !important;
  margin-right: 8px !important;
  color: var(--secondary-color);
}
.product-card .product-card-body .price .discounted-price {
  font-size: 12px;
  margin-left: 8px;
}

.product-card .product-card-body .product_varients_drop {
  width: 100%;
  display: flex;
}

.product-card .product-card-footer button {
  background: none;
  font-size: 1.6rem;
  padding: 6px;
}

.product-card .product-card-footer .input-to-cart {
  background: var(--secondary-color);
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

input::placeholder {
  text-align: center !important;
}
.product-card .product-card-footer .input-to-cart input {
  font-size: 14px;
  color: var(--text-field-color);
  width: 100%;
}

.product-card .product-card-footer .add-to-cart.active,
.product-card .product-card-footer .input-to-cart.active {
  display: block;
}

.product-card .product-card-footer .input-to-cart.active {
  background: var(--secondary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-card .product-card-footer .input-to-cart.active * {
  color: var(--text-field-color);
  font-size: 14px;
}

.product-card .product-card-footer button:hover {
  background: var(--secondary-color);
  color: var(--text-field-color);
}

#productlist {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 2rem;
}

#products {
  flex-wrap: nowrap !important;
}

#productlist .row .filter-container {
  display: inline-flex;
}

#productlist .row .filter-row {
  background: var(--second-cards-color);
  padding: 10px;
  border-radius: 10px;
}
#productlist #filterBySizeContainer {
  overflow: auto;
  height: 450px;
}
#filteroffcanvasExample {
  overflow: auto;
}
#productlist .row .filter-row > div {
  padding: 5px;
  cursor: pointer;
}

#productlist .row .filter-row .product-filter-headline h5 {
  font-size: 22px;
}
#productlist .row .filter-row .product-filter-headline {
  font-size: 2.5rem;
  padding: 5px;
  border-bottom: 3px solid rgba(128, 128, 128, 0.253);
  border-image: linear-gradient(
      to right,
      var(--secondary-color) 25%,
      rgba(128, 128, 128, 0.253) 0%,
      rgba(128, 128, 128, 0.253) 75%
    )
    2;
}
#productlist .row .filter-row .product-filter-headline span.btn {
  font-size: 16px;
}
#productlist .row .filter-row h5 {
  font-size: 2.5rem;
  padding: 5px;
  border-bottom: 3px solid rgba(128, 128, 128, 0.253);
  border-image: linear-gradient(
      to right,
      var(--secondary-color) 25%,
      rgba(128, 128, 128, 0.253) 0%,
      rgba(128, 128, 128, 0.253) 75%
    )
    2;
}

#productlist .row .filter-row .image-container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

#productlist .row .filter-row img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

#productlist .row .filter-row .brand-count {
  background: #51bd8980;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid var(--secondary-color);
  font-size: 1.5rem;
}

#productlist .row .filter-row .filter-bar:hover {
  background: var(--secondary-color-light);
}

#productlist .row .filter-row .filter-bar.active {
  border-right: 3px solid var(--secondary-color);
  background: var(--secondary-color-dark);
}
#productlist .row .filter-row .filter-bar.active p {
  color: var(--text-field-color);
}
#productlist .row .filter-row .slider {
  width: 100%;
  margin: 30px 0px;
  border-radius: 8px;
  position: relative !important;
}

#productlist .row .filter-row .slider .track {
  height: 5px;
  top: 0;
  border-radius: 8px;
  padding: 15px;
}

#productlist .row .filter-row .slider .track.track-1 {
  background: var(--secondary-color);
}

#productlist .row .filter-row .thumb {
  background: var(--secondary-color);
  color: var(--text-field-color);
  border-radius: 50%;
  cursor: pointer;
  height: 25px;
  width: 25px;
  /* top: -10px; */
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 2px 1px #51bd8980;
  /* position: absolute !important; */
  display: flex;
  justify-content: center;
  align-items: center;
}

#productlist .row .filter-view {
  background: var(--second-cards-color);
  padding: 10px;
  border-radius: 10px;
}

#productlist .row .filter-view .icon {
  padding: 0 5px;
}

#productlist .row .filter-view .icon.active {
  background: var(--secondary-color);
}

#productlist .row .filter-view .icon:hover {
  background: var(--secondary-color);
}

#productlist .row .filter-view .select {
  position: relative;
  display: flex;
  width: 8em;
  overflow: hidden;
}

.form-select {
  font-size: 14px !important;
  width: 100% !important;
}

#productlist .row .product-card {
  display: flex;
}

#productlist .row .product-card.list-view {
  flex-direction: row;
  justify-content: space-around;
  max-height: 200px;
  max-width: 340px;
  min-width: 340px;
}

#productlist .row .product-card.list-view .image-container {
  width: fit-content;
  padding: 10px 0;
}

#productlist .row .product-card.list-view .image-container img {
  height: 95px;
  width: 95px;
}

#productlist .row .product-card.list-view .image-container a {
  float: left;
}

.productList_container {
  padding-left: 30px !important;
}

@media (max-width: 467px) {
  .productList_container {
    padding-left: 0 !important;
  }
}

.productList_content {
  margin: 20px 0 !important;
}

.productList_content
  .product-card
  .product-card-body
  .price
  .product_varients_drop
  .variant-section.dropdown
  .variant_selection_productList {
  width: 100%;
  background-color: #f2f3f9 !important;
  padding: 4px 10px !important;
  opacity: 0.7;
  align-items: center;
  display: flex;
  border: 0;
  font-size: 14px !important;
}

.productList_content
  .product-card
  .product-card-body
  .price
  .product_varients_drop
  .variant-section.dropdown
  .variant_selection_productList
  span {
  display: flex;
  align-items: center;
  color: black !important;
  font-weight: 500;
  font-size: 14px !important;
}

.product-details-view .top-wrapper {
  margin-top: 30px;
  background-color: #fff;
  border-radius: 10px;
}

.product-details-view .body-wrapper {
  background-color: #fff;
  font-size: 14px;
  display: flex;
  border-radius: 5px;
}

.product-details-view .body-row {
  display: flex;
  justify-content: center;
}

.product-details-view .image-wrapper {
  padding: 15px;
}

.product-details-view .image-wrapper .main-image {
  width: 100%;
  text-align: center;
  border-radius: 5px !important;
}

.product-details-view .image-wrapper .main-image img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 5px;
  object-fit: fill;
}

.product-details-view .image-wrapper .sub-images-container {
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  gap: 20px;
}

.product-details-view
  .sub-images-container
  .slick-arrow.slick-next
  .next-arrow {
  position: absolute;
  background: #fff;
  border-radius: 0pc 8px 8px 0px;
  width: 40px;
  height: 40px;
  right: 0;
  left: auto;
  top: -14px;
  padding: 8px;
  box-shadow: 4px 0px 100px 5px rgb(0 0 0 / 5%);
  border: 5px solid #f7f7f7;
}

.related-product-container {
  position: relative;
}

.related-product-container .slick-arrow.slick-next .next-arrow {
  width: 40px;
  height: 80px;
  top: -30px;
  right: 0px;
}
.related-product-container .slick-arrow.slick-prev .prev-arrow {
  width: 40px;
  height: 80px;
  top: -30px;
  left: 0px;
}

.product-details-view .slick-arrow.slick-next {
  right: 0 !important;
  margin-right: 0 !important;
}

.product-details-view .slick-arrow.slick-prev {
  left: auto !important;
}

.product-details-view
  .sub-images-container
  .slick-arrow.slick-prev
  .prev-arrow {
  position: absolute;
  background: #fff;
  border-radius: 0pc 8px 8px 0px;
  width: 40px;
  height: 40px;
  right: auto;
  left: 0;
  top: -14px;
  padding: 8px;
  box-shadow: 4px 0px 100px 5px rgb(0 0 0 / 5%);
  border: 5px solid #f7f7f7;
}

/* .description-wrapper {
  margin: 30px 0 0 0;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  overflow: auto;
} */

/* .fusion-sep-clear {
  display: none !important;
}

.fusion-separator {
  display: none !important;
} */

/* .description-wrapper img {
  height: auto;
  max-width: 100%;
  object-fit: contain;
} */

/* .description-wrapper .title {
  box-sizing: border-box;
  border: 0px;
  font-size: 20px;
  margin: 0px 0px 10px;
  outline: 0px;
  padding: 0px;
  vertical-align: baseline;
  overflow-wrap: break-word;
  line-height: 50px;
  font-weight: 800;
  color: rgb(35, 35, 35);
  border-bottom: 1px solid #8080802b;
} */

.related-product-wrapper {
  margin: 20px auto;
}

.related-product-wrapper h5 {
  box-sizing: border-box;
  border: 0px;
  font-size: 20px;
  margin: 0px 0px 10px;
  outline: 0px;
  padding: 0px;
  vertical-align: baseline;
  overflow-wrap: break-word;
  line-height: 50px;
  font-weight: 800;
  color: #232323;
  padding: 15px;
  background-color: #fff;

  border-radius: 10px;
}
.imageListSlider .slick-slide {
  width: 100px !important;
}
.product-details-view .image-wrapper .sub-images-container .sub-image {
  width: 90px !important;
  height: 90px !important;
  padding: 10px;
  border-radius: 8px;
  margin: 0 20px 0 0;
  cursor: pointer;
  aspect-ratio: 1/1 !important;
}

.related-product-container .slick-prev {
  left: 5px !important;
  z-index: 10 !important;
}

@media (max-width: 480px) {
  .related-product-container .slick-dots {
    bottom: -17px;
  }

  .related-product-container .slick-dots li button:before {
    font-family: "slick";
    font-size: 8px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    color: var(--secondary-color);
  }
}

.related-product-container .slick-next {
  right: 8px;
}

@media (max-width: 350px) {
  .product-details-view .image-wrapper .sub-images-container .sub-image {
    width: auto;
  }
}

.product-details-view .image-wrapper .sub-images-container .sub-image.active {
  border: 1px solid var(--secondary-color);
  border-radius: 8px;
}

.product-details-view .image-wrapper .sub-images-container .sub-image img {
  width: 100%;
  height: 100%;
}

.product-details-view .detail-wrapper {
  padding: 15px;
  width: 100%;
}

.product-details-view .detail-wrapper .top-section {
  display: flex;
  flex-direction: column;
}

.product-details-view .detail-wrapper .top-section .product_name {
  margin-bottom: 12px;
  color: var(--secondary-color);
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0px;
  opacity: 1;
  line-height: 38px;
}
/* .product-details-view .detail-wrapper .top-section #price-section-quickview {
  color: var(--secondary-color) !important;
  font-size: 20px;
} */
.product-details-view .detail-wrapper .top-section .product-brand {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  font-size: 16px;
}

.product-details-view .detail-wrapper .top-section .product-brand .brand- {
  color: #51bd88;
  margin-left: 5px;
}

.product-details-view .detail-wrapper .top-section #price-section {
  font-size: 22px;
  font-weight: 800;
  display: flex;
  align-items: center;
  color: var(--secondary-color) !important;
  text-align: left;
  letter-spacing: 0px;
}
.product-details-view .detail-wrapper .top-section #price-section #fa-rupee {
  color: var(--secondary-color) !important;
}
.product-details-view .detail-row .top-section .price::before,
.product-details-view .detail-row .top-section .not-price::before {
  content: "\20B9";
}

.product-details-view .detail-row .top-section .not-price {
  text-decoration: line-through;
}

.product-details-view .detail-wrapper .bottom-section .fssai-details {
  display: flex;
  justify-self: flex-start;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
}
.product-details-view
  .detail-wrapper
  .bottom-section
  .fssai-details
  .image-container
  img {
  height: 25px;
  object-fit: contain;
}

.product-details-view
  .detail-wrapper
  .bottom-section
  .fssai-details
  .fssai-license-no
  span {
  font-size: 16px;
  font-weight: 600;
}
.product-details-view .detail-wrapper .bottom-section .product-overview {
  width: 100%;
  background-color: #f7f7f7;
  padding: 15px;
}

.product-details-view
  .detail-wrapper
  .bottom-section
  .product-overview
  .product-seller
  .seller-title,
.product-details-view
  .detail-wrapper
  .bottom-section
  .product-overview
  .product-tags
  .tag-title {
  padding: 10px 5px;
  font-size: 16px;
  font-weight: 600;
}

.product-details-view
  .detail-wrapper
  .bottom-section
  .product-overview
  .product-seller
  .seller-name,
.product-details-view
  .detail-wrapper
  .bottom-section
  .product-overview
  .product-tags
  .tag-name {
  color: var(--secondary-color);
  font-size: 16px;
}

.product-details-view .detail-wrapper .bottom-section .share-product-container {
  margin-top: 36px;
}

.product-details-view
  .detail-wrapper
  .bottom-section
  .share-product-container
  span {
  font-size: 14px;
  font-weight: 700;
}

.product-details-view .detail-wrapper .bottom-section .share-product-container,
.share-product {
  display: flex;
  list-style: none;
  align-items: center;
}

.product-details-view .detail-wrapper .bottom-section .share-product {
  margin: 0 !important;
}

.product-details-view .detail-wrapper .key-feature p,
.product-details-view .detail-wrapper .bottom-section p {
  font-weight: bold;
}

.product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  .bottom-section-content
  .variant-section {
  width: auto;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
  display: flex;
  padding: 0;
  margin: 5px;
}
.product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  .bottom-section-content
  .variant-element
  .element_container {
  width: 100%;
}
.product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  .bottom-section-content
  .variant-element.out_of_stock {
  background-color: #000;
  opacity: 0.6;
  border-radius: 5px;
}
.product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  .bottom-section-content
  .variant-element.out_of_stock {
  background-color: #ddd;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  border-radius: 3px;
}
.product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  .bottom-section-content
  .variant-element.out_of_stock
  .element_container
  .top-section {
  background-color: #ddd;
}
.product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  .bottom-section-content
  .variant-element
  .element_container
  .top-section
  input[type="radio"] {
  -webkit-appearance: none; /* Remove default appearance
  -moz-appearance: none;
  appearance: none;
  width: 13px; /* Set size of radio button */
  margin: auto;
  height: 13px;
  border-radius: 50%; /* Make it round */
  border: 1px solid; /* Add border */
  outline: none; /* Remove outline */
}
.product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  .bottom-section-content
  .variant-element.active
  .element_container
  .top-section
  input[type="radio"]:checked {
  background-color: var(
    --secondary-color
  ); /* Change background color when checked */
  padding: 3px;
  border: 3px solid #fff;
}
.product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  .bottom-section-content
  .variant-element.active
  .element_container
  .top-section {
  background-color: var(--secondary-color);
}
.product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  .bottom-section-content
  .variant-element
  .element_container
  .top-section {
  background-color: #fff;
  padding: 8px 26px;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid #bfbfbf;
}

.product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  .bottom-section-content
  .variant-element
  .element_container
  .bottom-section {
  text-align: center;
}

@media (max-width: 424px) {
  .product-details-view
    .body-wrapper
    .detail-wrapper
    .bottom-section
    .bottom-section-content {
    display: block !important;
    gap: 3px;
  }

  .product-details-view
    .body-wrapper
    .detail-wrapper
    .bottom-section
    .bottom-section-content
    #Add-to-cart-quickview {
    margin: 0 !important;
    padding: 8px;
  }

  .product-details-view
    .body-wrapper
    .detail-wrapper
    .bottom-section
    .bottom-section-content
    #select-product-variant-quickview {
    width: 100%;
  }
}

.product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  #select-product-variant-productdetail {
  padding: 12px 18px;
  border-radius: 8px;
  font-size: 18px;
  color: #8b8b8b;
  font-weight: 600;
  background-color: #f7f7f7;
  cursor: pointer;
}

.product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  .variant_selection-productdetail {
  padding: 12px 18px 12px 12px !important;
  font-size: 14px;
  width: 100%;
  border: 0;
  display: flex;
  background-color: #f7f7f7 !important;
  color: #000;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  .variant_selection-productdetail-single {
  padding: 12px 18px 12px 12px !important;
  font-size: 14px;
  border-radius: 5px;
  width: 100%;
  opacity: 1;
  background-color: #f7f7f7 !important;
}

.product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  .variant_selection-productdetail-single
  span {
  text-decoration: line-through;
}

.product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  .bottom-section-content
  #dropdown-product-variant-productdetail {
  border-radius: 8px;

  width: 100%;
}

.dropdown-toggle::after {
  color: #000;
}

/* .product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  #select-product-variant-quickview {
  border-radius: 8px;
  font-size: 18px;
  color: #8b8b8b;
  width: 30%;
  font-weight: 600;
  background-color: #f7f7f7;
  cursor: pointer;
} */

#select-product-variant-quickview .dropdown-menu {
  font-size: 14px;
  opacity: 1;
}

#select-product-variant-quickview .dropdown-menu a:active {
  background-color: var(--secondary-color);
}

#select-product-variant-quickview .dropdown-menu span {
  text-decoration: line-through;
}

.product-details-view #price-section-quickview {
  display: flex;
  /* align-items: baseline !important; */
  font-weight: 700;
  font-size: 20px;
  color: var(--secondary-color) !important;
}
.product-details-view #price-section-quickview #priceContainer {
  color: var(--secondary-color) !important  ;
}
.product-details-view .body-wrapper .detail-wrapper .bottom-section .dropdown {
  width: 100%;
}

.product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  .dropdown
  .dropdown-menu {
  opacity: 1;
  font-size: 14px;
}

.product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  .dropdownail
  .dropdown-menu
  a:active {
  background-color: var(--secondary-color);
}

.product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  .dropdown
  .dropdown-menu
  .original-price,
.variant_selection-productdetail .original-price {
  text-decoration: line-through;
}

.product-details-view .detail-wrapper .bottom-section button {
  margin: 0 10px;
  background: none;
  border: 1px solid #80808080;
  border-radius: 5px;
}

.product-details-view .detail-row .bottom-section button:hover {
  background: var(--secondary-color);
  color: var(--text-field-color);
  font-size: 14px;
}

.product-details-view
  .detail-wrapper
  .bottom-section
  #Add-to-cart-productdetail {
  width: 35%;
  margin: 10px 5px 10px 0;
  padding: 8px;
}

@media (max-width: 424px) {
  .product-details-view
    .body-wrapper
    .detail-wrapper
    .bottom-section
    #select-product-variant-productdetail {
    width: 100%;
  }

  .product-details-view
    .detail-wrapper
    .bottom-section
    #Add-to-cart-productdetail {
    width: 35%;
    margin: 0;
    padding: 8px;
  }
}

.product-details-view
  .detail-wrapper
  .bottom-section
  .bottom-section-content
  .variants,
.product-details-view
  .detail-wrapper
  .bottom-section
  .bottom-section-content
  .cart_option {
  width: 100%;
}

.product-details-view .detail-wrapper .bottom-section .bottom-section-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  position: relative;
}

.product-details-view
  .detail-wrapper
  .bottom-section
  .bottom-section-content
  .element_container
  .top-section {
  background-color: var(--secondary-color);
}

.product-details-view .detail-wrapper .bottom-section .product-add-container {
  display: flex;
  align-items: center;
  /* margin-top: 10px; */
  width: 100%;
}

.product-details-view .detail-wrapper .bottom-section #Add-to-cart-quickview,
#input-cart-quickview {
  padding: 8px 0;
  width: 30%;
  margin: 10px 5px 10px 0;
}

.product-details-view .product-seller {
  margin-bottom: 10px;
}

.product-details-view .detail-wrapper .bottom-section .input-to-cart {
  background: #f7f7f7;
  display: inline-flex;
  padding: 5px 0;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  width: 35%;
}

.product-details-view .detail-wrapper .bottom-section .input-to-cart button {
  background: #8b8b8b;
  border-radius: 5px;
}

.product-details-view .detail-wrapper .bottom-section .wishlist-product {
  padding: 8px;
  width: auto;
  margin: 10px 5px 10px 0;
}

@media (max-width: 434px) {
  .product-details-view .detail-wrapper .bottom-section .wishlist-product {
    margin: 15px 0 0 10px !important;
  }
}

.product-details-view .detail-wrapper .bottom-section button:hover,
.wishlist-product:hover svg path {
  background-color: var(--secondary-color);
  color: var(--text-field-color);
}

.product-details-view
  .detail-wrapper
  .bottom-section
  .variant_selection-productdetail:hover,
.product-details-view
  .detail-wrapper
  .bottom-section
  .variant_selection-productdetail:hover
  .dropdown-toggle::after {
  background-color: #f2f3f9;
}

.product-details-view
  .detail-wrapper
  .bottom-section
  .input-to-cart.active
  span {
  background: #a7eeccc9;
  color: var(--secondary-color);
  padding: 8px 15px;
}

.wishlist-button {
  padding: 5px;
}

.product-details-view .top-row {
  background: #fff;
  margin-top: 20px;
  padding: 20px;
  border-radius: 5px;
}

.product-details-view .description-row {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
}

.product-details-view .description-row .title {
  font-size: 2.5rem;
  font-weight: bolder;

  border-bottom: 3px solid rgba(128, 128, 128, 0.253);
  border-image: linear-gradient(
      to right,
      var(--secondary-color) 10%,
      rgba(128, 128, 128, 0.253) 0%,
      rgba(128, 128, 128, 0.253) 75%
    )
    2;
}

.product-details-view .description-row .description {
  overflow-x: auto;
  scroll-behavior: smooth;
  font-size: 1.5rem;
}

.product-details-view .related-product-row {
  margin-bottom: 20px;
}

.product-details-view .related-product-row h5 {
  padding: 20px;
  font-size: 2.5rem;
  font-weight: bolder;

  background: #fff;
  padding: 20px;
  border-radius: 8px;
}

.related-product-container .product-card {
  margin: 6px;
}

.related-product-container .product-card .relatedproduct-dropdown {
  border-radius: 8px;
  width: 100%;
}

.related-product-container
  .product-card
  .relatedproduct-dropdown
  .variant_selection-relatedProduct {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 0;
  background-color: #f2f3f9 !important;
  color: black !important;
  border-radius: 5px;
  font-size: 14px !important;
  width: 100%;
  padding: 4px 10px !important;
  opacity: 0.7;
  align-items: center;
}

.pagination,
.pagination li a {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.pagination li {
  background-color: inherit;
}

.pagination a {
  font-weight: 500;
  padding-top: 1px;
  text-decoration: none;
  min-width: 44px;
  min-height: 44px;
  color: #000;
  box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.35);
}

.pagination li.active {
  background: var(--secondary-color);
  border-radius: 50%;
}
.pagination li.active a {
  color: #fff;
}
@media (max-width: 48rem) {
  .product-details-view .body-row {
    flex-direction: column;
    align-items: center;
  }

  .product-details-view .image-row {
    width: 250px;
  }

  .product-details-view .image-row .main-image {
    height: 240px;
  }

  .product-details-view .detail-row {
    border-left: none;
    border-top: 2px solid #eee;
    margin-left: 0px;
    margin-top: 20px;
    padding: 20px 5px;
  }

  .product-details-view .image-row .sub-images-container .sub-image {
    width: 50px;
    height: 50px;
  }

  .product-details-view .detail-row .top-section .product-overview {
    flex-direction: column;
    align-items: flex-start;
  }

  .product-details-view
    .detail-row
    .top-section
    .product-overview
    .product-seller,
  .product-details-view
    .detail-row
    .top-section
    .product-overview
    .product-tags,
  .product-details-view
    .detail-row
    .top-section
    .product-overview
    .product-category {
    border-right: none;
    border-bottom: 1px solid #eee;
    width: 100%;
  }

  .product-details-view .description-row .description img {
    width: 90vw;
  }
}

/* Products Page */

.product_list_container {
  margin-top: 10px;
}

.product_list_container .product-card {
  margin: 0 10px;
}

.productList_content .product-card .price .product_list_dropdown_span,
.productList_content .product-card .price .product_list_dropdown_span span {
  font-size: 14px !important;
  color: var(--primary-color) !important;
  display: flex;
  width: auto;
  font-weight: 500;
}

@media (max-width: 764px) {
  .pagination a {
    min-width: 30px;
    min-height: 30px;
  }

  .product-details-view .image-wrapper {
    border-right: none;
  }
}

#eye:hover {
  background-color: rgba(56, 206, 116, 1) !important;
}

#eye {
  border-radius: 50%;
  margin: -5px;
}

/* button:disabled {
  background-color: #ddd;
  cursor: default !important;
  color: #000 !important;
} */
.buttonDisabled {
  background-color: #ddd;
  cursor: default !important;
  color: #000 !important;
}

[dir="rtl"] .slick-next {
  right: -20px;
  left: auto;
}

.no-product {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 1400px) {
  .prev-arrow {
    left: 23px;
  }
}

.ratingPopOverBody {
  width: 250px !important;
  /* height: 250px !important; */
  font-size: 14px;
}
.ratingBar {
  background: var(--secondary-color-light);
  /* color: var(--secondary-color) !important; */
  transform: rotate(0deg) !important;
  width: 150px !important;
  height: 4px !important;
}
.ratingBar .progress-bar {
  background-color: var(--secondary-color) !important;
}
.ratings-section-redirect {
  text-decoration: none;
  color: var(--bs-black);
}
